const hostedZone = process.env.HOSTED_ZONE || '';

export const customDomainName = process.env.CUSTOM_DOMAIN_NAME || '';

export const fullStoryOrganizationId = process.env.NEXT_PUBLIC_FULLSTORY_ORGANIZATION_ID || '';
export const googleAnalyticsID = process.env.NEXT_PUBLIC_GA_ID || '';
export const googleTagManagerID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const nodeEnvironment = process.env.NODE_ENV || 'development';
export const isSearchEngineIndexable = process.env.SEARCH_ENGINE_INDEXABLE || 'false';
export const sentryDns = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
export const isNewsFeatureFlagEnabled = process.env.NEXT_PUBLIC_FEATURE_FLAG_NEWS_PAGE === 'true';
export const isCostPlusPageFeatureFlagEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_COSTPLUS_PAGE === 'true';
export const contentfulSpaceId = process.env.CONTENTFUL_SPACE_ID || '';
export const contentfulAccessToken = process.env.CONTENTFUL_ACCESS_TOKEN || '';
export const contentfulEnvironment = process.env.CONTENTFUL_ENVIRONMENT || 'develop';

export const isLocalEnvironment = nodeEnvironment === 'development';
export const isProductionEnvironment =
  process.env.NEXT_PUBLIC_APP_STAGE === 'production' || process.env.STACK_STAGE === 'production';
export const agileApiUrl = process.env.API_URL || '';

export const customersTableName = process.env.CUSTOMERS_TABLE_NAME || '';

export const agileNoReplyEmailAddress = `no-reply@${hostedZone}`;
export const developmentEmailReceiver = process.env.DEVELOPMENT_EMAIL_RECEIVER;

export const signupTopicArn = process.env.SIGNUP_TOPIC_ARN || '';
export const slackWorkspaceId = process.env.SLACK_WORKSPACE_ID || '';
export const signupChannelId = process.env.SIGNUP_CHANNEL_ID || '';
